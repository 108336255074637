
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IPopup } from '@/utils/types'
import { Prop } from 'vue-property-decorator'
import { constant } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
  emits: [],
})
export default class PreviewPopup extends Vue {
  @Prop()
  popup!: IPopup

  selectedPlayDevice = 'sp'

  get canShowPreview(): boolean {
    if (this.popup && this.popup.images.length) {
      return this.popup.images[0][this.selectedPlayDevice].img_org
    }

    return false
  }

  get animationCls() {
    if (this.popup.animation_type === constant.animation_type.fade_in) {
      return 'preview-popup--fade-in'
    }
  }

  handleReplayPreview() {
    const currentType = this.popup.animation_type
    this.popup.animation_type = 0
    setTimeout(() => {
      this.popup.animation_type = currentType
    }, 200)
  }
}
