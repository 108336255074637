<template>
  <div class="q-pa-md q-gutter-sm">
    <q-btn-toggle
      size="sm"
      v-model="selectedPlayDevice"
      toggle-color="primary"
      :options="[
        { label: 'SP', value: 'sp' },
        { label: 'PC', value: 'pc' },
      ]"
    />
    <q-btn color="white" text-color="black" label="Play" size="sm" @click="handleReplayPreview" class="btn-custom" />
  </div>
  <div :class="'preview-device--' + selectedPlayDevice">
    <div class="preview-device--container">
      <div :class="[`preview-device--popup preview-device--popup preview-popup`, animationCls]" v-if="canShowPreview">
        <div v-show="true" :class="[`preview-popup--close preview-popup--close `]"></div>
        <div :class="[`preview-popup--body preview-popup--body `]">
          <div class="preview-popup--img-block">
            <div class="image-item-height-position" v-for="(image, imageIndex) of popup.images" :key="imageIndex">
              <img :src="image[selectedPlayDevice].img_thumb" class="preview-popup--img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IPopup } from '@/utils/types'
import { Prop } from 'vue-property-decorator'
import { constant } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
  emits: [],
})
export default class PreviewPopup extends Vue {
  @Prop()
  popup!: IPopup

  selectedPlayDevice = 'sp'

  get canShowPreview(): boolean {
    if (this.popup && this.popup.images.length) {
      return this.popup.images[0][this.selectedPlayDevice].img_org
    }

    return false
  }

  get animationCls() {
    if (this.popup.animation_type === constant.animation_type.fade_in) {
      return 'preview-popup--fade-in'
    }
  }

  handleReplayPreview() {
    const currentType = this.popup.animation_type
    this.popup.animation_type = 0
    setTimeout(() => {
      this.popup.animation_type = currentType
    }, 200)
  }
}
</script>

<style lang="scss" scoped>
@keyframes fade_in_show {
  0% {
    opacity: 0;
    transform: translate(0, 0) scale(0);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
}

@keyframes animate_rotate {
  from {
    transform: translate(0, 0) rotate(0deg);
    opacity: 0;
  }
  to {
    transform: translate(0, 0) rotate(360deg);
    opacity: 1;
  }
}

@keyframes animate_left {
  from {
    transform: translate(-100%, 0);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes animate_right {
  from {
    transform: translate(100%, 0);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes animate_top {
  from {
    transform: translate(0, -100%);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes animate_bottom {
  from {
    transform: translate(0, 100%);
    opacity: 0;
  }
  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slider-item-fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.preview-device {
  position: relative;
  $root: &;
  &--sp {
    overflow: hidden;
    position: relative;
    background: url('/img/devices/iphone.png') no-repeat center;
    background-size: contain;
    margin: auto;
    width: 390px;
    height: 750px;
    #{$root} {
      &--container {
        border: 40px solid transparent;
        border-top-width: 96px;
        border-bottom-width: 106px;
      }
    }

    .preview-device--popup--overlay {
      width: 100%;
    }
  }
  &--pc {
    overflow: hidden;
    position: relative;
    background: url('/img/devices/pc.png') no-repeat center;
    background-size: contain;
    margin: auto;
    height: 450px;
    width: 650px;
    #{$root} {
      &--container {
        border: 40px solid transparent;
        border-top-width: 58px;
        border-bottom-width: 88px;
      }
    }
  }

  &--container {
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: #36b2da;
    }
  }
  &--buttons {
    margin: auto;
  }
  &--popup {
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    width: 270px;
    min-height: 20px;
    &--overlay {
      bottom: 0;
    }
  }
}

.preview-popup {
  display: none;
  &--body {
    overflow: hidden;
    border-radius: 4px;
    &--overlay {
      border-radius: 0;
    }
  }
  &--img {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    height: auto;
    max-width: 100%;
  }
  &--close {
    position: absolute;
    padding: 4px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 100%;
    right: 0;
    top: -28px;
    padding: 12px;
    cursor: pointer;

    &--overlay {
      top: 5px;
      right: 5px;
      border-radius: 0;
      padding: 8px;
      &:before,
      &:after {
        height: 8px !important;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &:before,
    &:after {
      position: absolute;
      left: 11px;
      content: ' ';
      height: 12px;
      width: 2px;
      background-color: #fff;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
  &--img-block {
    text-align: center;
  }

  &--countdown {
    padding: calc(10px / 1.5); // Scale out
    text-align: center;
    font-weight: 900;
  }

  &__redisplay {
    bottom: 20px;
    position: absolute;
    z-index: 1;
    left: 5px;
    &__button {
      width: 50px;
      transform: scale(0.7);
      display: inline-block;
    }
    &__balloon-message {
      transform: scale(0.7);
      display: inline-block;
      position: absolute;
      left: 15px;
      top: -60px;
      width: 220px;
    }
    &__countdown {
      position: absolute;
      z-index: 1;
      width: 50px;
      text-align: center;
      transform: scale(0.7);
      &--white {
        bottom: 12px;
        color: #fff;
      }
      &--black {
        bottom: 18px;
        color: #000;
        font-size: 10px;
      }
    }
  }

  &--fade-in {
    display: block;
    animation: fade_in_show 0.5s;
  }
  &--from-top {
    display: block;
    animation: animate_top 0.5s;
  }
  &--from-bottom {
    display: block;
    animation: animate_bottom 0.5s;
  }
  &--from-left {
    display: block;
    animation: animate_left 0.5s;
  }
  &--from-right {
    display: block;
    animation: animate_right 0.5s;
  }

  &--from-rotate {
    display: block;
    animation: animate_rotate 0.5s;
  }
}
</style>
