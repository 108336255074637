
import { Options, mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import cloneDeep from 'lodash/cloneDeep'
import UploadApi from '@/api/upload'
import { IApp, ICampaign, IMedia, IPopup, IUploadFileResponse } from '@/utils/types'
import { ACTION_APP, ACTION_CAMPAIGN, ACTION_POPUP } from '@/store/actions'
import { constant } from '@/utils/constants'
import UploadingProgress from '@/components/common/ui/UploadingProgress.vue'
import PreviewPopup from '@/components/popup/preview/PreviewPopup.vue'
import { isValidUrl } from '@/utils/validators'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import MediaUploader from '@/components/media-manager/MediaUploader.vue'

@Options({
  components: { MediaUploader, UploadingProgress, PreviewPopup },
  directives: { maska },
  emits: [],
  async beforeRouteLeave() {
    if (!this) {
      return
    }
    const result = await this.checkDraftValue()
    if (result) {
      return true
    } else {
      return false
    }
  },
})
export default class PopupSettingForm extends mixins(BaseFormMixin) {
  form: IPopup = {
    _id: '',
    app_id: '',
    campaign_id: '',
    is_active: false,
    popup_name: '',
    images: [],
    animation_type: constant.animation_type.fade_in,
  }

  errorImageMessage = ''
  files: File[] = []
  selectedCampaign: ICampaign = {}
  selectedApp: IApp = {}

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get campaignId() {
    return this.$route.params.campaign_id || ''
  }

  get actionType() {
    return this.$route.params.action_type || ''
  }

  get popupId() {
    return this.$route.params.popup_id || ''
  }

  get requiredRule() {
    const requiredRule = {
      popup_name: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.popup.popup_name'),
      }),
      messageImage: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.image'),
      }),
    }
    return requiredRule
  }

  get isNew() {
    return !this.form?._id
  }

  validate() {
    this.errorImageMessage = ''
    if (!this.form.images || this.form.images.length === 0) {
      this.errorImageMessage = this.requiredRule.messageImage
      return false
    }

    return true
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(async (success: boolean) => {
        if (!success || !this.validate()) {
          return
        }

        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let item = null
    if (this.isNew) {
      this.form.app_id = this.selectedAppId
      this.form.campaign_id = this.campaignId

      item = await dispatch(ACTION_POPUP.ADD, {
        ...this.form,
      })
    } else {
      item = await dispatch(ACTION_POPUP.UPDATE, {
        _id: this.form._id,
        ...this.form,
      })
    }

    if (item) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    this.initForm = cloneDeep(this.parentForm)
    this.handleBackToPopupSetting()
  }

  handleBackToPopupSetting() {
    this.goto('popups', {
      app_id: this.selectedAppId,
      campaign_id: this.campaignId,
    })
  }

  goto(name: string, params = {}) {
    this.$router.push({
      name,
      params,
    })
  }

  @Watch('actionType', { immediate: true })
  async fetchSelectingPopup() {
    if (!this.selectedAppId || this.campaignId === '') {
      return
    }
    this.selectedApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.selectedAppId)

    this.selectedCampaign = await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_ITEM, this.campaignId)
    if (this.popupId !== '') {
      const data: IPopup = await this.$store.dispatch(ACTION_POPUP.LOAD_ITEM, this.popupId)

      if (data) {
        this.form = { ...this.form, ...data }
        if (this.actionType === constant.ACTION_TYPE.COPY) {
          this.form._id = ''
          this.form.popup_name = this.form.popup_name + ' Copy'
          this.form.is_active = false
        }
      }
    }

    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
  }

  async onSelectMedia(file: IMedia) {
    this.errorImageMessage = ''
    if (file) {
      const imageSetting = {
        img_org: file.url ?? '',
        img_thumb: file.thumb ?? '',
        filename: file.name ?? '',
      }
      this.form.images = [
        {
          pc: imageSetting,
          sp: JSON.parse(JSON.stringify(imageSetting)),
        },
      ]
    }
  }

  @Watch('files')
  async handleUpload() {
    this.errorImageMessage = ''
    if (!this.files.length) {
      return false
    }
    try {
      const uploaded: IUploadFileResponse = await UploadApi.uploadFile(this.files[0])
      if (uploaded) {
        const imageSetting = {
          img_org: uploaded.file_url_org ?? '',
          img_thumb: uploaded.file_url_thumb ?? '',
          filename: uploaded.filename ?? '',
        }
        this.form.images = [
          {
            pc: imageSetting,
            sp: JSON.parse(JSON.stringify(imageSetting)),
          },
        ]
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.files = []
    }
  }

  onRemoveImage(index: number) {
    this.form.images.splice(index, 1)
  }

  customIsValidUrl(url) {
    if (!isValidUrl(url)) {
      return false || this.$t('label.target_setting.please_input_a_valid_unique_url')
    }

    return true || ''
  }

  async created() {
    this.fetchSelectingPopup()

    if (this.popupId === '') {
      this.$nextTick(() => {
        this.$refs.formRef.validate()
      })
    }
  }
}
